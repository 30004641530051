import React from 'react';
import Layout from "../../components/layout";

const Caboolture = () => {
    return (
        <Layout>
            <h1>Caboolture</h1>
            <h2>Instructors</h2>
            <p><a href="#rob">Sensei Rob Williams</a></p>

            <p><a href="#dave">Sensei Dave Martin</a><br/>
                Email: <a href="mailto:btcburp@bigpond.net.au">btcburp@bigpond.net.au</a><br/>
                Phone: 0419 998 486</p>

            <h2>Class Times</h2>
            <p><strong>Monday:</strong><br/>
            <strong>Kids</strong>, 6.00pm-7.00pm (Beginners and Adults also welcome)<br/>
                <strong>Adults</strong>, 6.00pm-7.30pm</p>

            <p><strong>Wednesday:</strong><br/>
                <strong>Kids</strong>, 6.00pm-7.00pm (Beginners and Adults also welcome)<br/>
                <strong>Adults</strong>, 6.00pm-7.30pm</p>

            <p><strong>Prices</strong><br/>
            <strong>Children:</strong> $10.00<br/>
            <strong>Adults:</strong> $10.00<br/>
            <strong>Yearly Registration:</strong> $50.00</p>


            <p><strong>Location</strong><br/>
                Morayfield State High School, Visentin Rd opposite Rail Station, Multi Purpose Shelter</p>

            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3551.5189556121895!2d152.9518288757251!3d-27.10845980141151!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b93f17d50c4b53f%3A0x9ea660bf7fc152f4!2sMorayfield%20State%20High%20School!5e0!3m2!1sen!2sau!4v1700058551261!5m2!1sen!2sau"
                width="400" height="300" frameborder="0" title="caboolture"></iframe>

            <hr/>
            <a href="#top" id="rob">Top</a>
            <h2>Sensei Rob Williams</h2>

            <p>Born: 1953 Victoria, Australia.<br/>
                Lives: Buderim on the Sunshine Coast of Queensland.</p>

            <p><strong>Training History:</strong><br/>
                <strong>Shodan</strong>, Okinawa Goju Ryu, IOGKF<br/>
                <strong>Founding member of TOGKA Inc</strong>, when Sensei Ravey resigned from the IOGKF.<br/>
                <strong>Yondan</strong>, Traditional Okinawa Goju Ryu Karate Do. TOGKA Inc.<br/>
                Principal Instructor & Developer of, Onna Do TOGKA Women's Self Protection Program.<br/>
                Certified Sports Coach and Trainer.<br/>
                Instructor of Excellence in Sports, Motivational Training, and courses in Basic non-board subject Karate
                for High School students.</p>

            <p><strong>Other martial training experience:</strong><br/>
                Boxing, Wrestling, Judo, Kobudo, Jo</p>

            <p><strong>Personal highlights:</strong></p>
            <ul>
                <li>The opportunity to train with Sensei Ravey, a man who is living Budo and Karate.</li>

                <li>Sunday Bloody Sundays, training sessions of the 80's and 90's in the Kilcoy Ranges.</li>

                <li>Rob Williams Sensei ran training seminars in Dar es Salaam, Tanzania, to induct our East African
                    Chief Instructor, Sensei Kheri Kivuli and students into the TOGKA. This was really an eye opener,
                    these guys would walk or run 1 hour or more to get to training, their dedication and zeal for hard
                    training was by far better than westerners, with our Tanzanian students were truly motivated with
                    95% of the students putting in 110% effort. They were the nicest and most sincere people Rob have
                    ever meet. They had nothing but would give you everything; it was a very humbling and satisfying
                    time for me. Our association sponsored their Dojo's with martial arts magazines, training manuals,
                    membership and equipment. He can remember presenting the Dar es Salaam Dojo with kick bags and using
                    them for the first time, their looks and smiles were worth the trip. Sensei Kheri Kivuli is a very
                    dedicated and proficient Karateka, and very worthy of promoting and preserving Traditional Okinawa
                    Goju Ryu in East Africa.
                </li>

                <li>Teaching in England TOGKA dojos.</li>
            </ul>


            <p>Rob first meet Sensei at the Caboolture Dojo in 1983. The early days of training were, by todays
                standards excessive, you thought yourself lucky if you could walk down the stairs after training, let
                alone the pain of the second day after. The bruises, lumps and broken bones all catch up. Fortunately
                these days we are a lot smarter with our training methods, and are aware of the damage that we can do to
                young developing bodies, so our training methods have become a little more scientific to protect new
                students, particularly the young.
            </p>

            <p>Sensei moved back to Japan after 18 months of training and Rob and a couple of men by the names of Dave
                Houghton and Nev McCasker ran the Dojo and had regular training with Sensei Peter Obereker from
                Newcastle.</p>

            <p>They trained after work starting at 6.00pm regularly going through until 10.30pm at the Dojo 3 nights a
                week, and at each other's homes on other nights and Saturdays. They trained with other styles but only
                as a supplement to their Okinawa Goju. As a supplement they ran the streets of Caboolture carrying large
                rocks, doing blocks and conditioning their palms by striking them on the rocks to take our minds off the
                pain in their legs. Quite a bit of the training in those days was devoted to heavy Kumite and
                supplementary training and conditioning.</p>

            <p>A warm up before training was 10 times around the oval with 5 activity stops of press ups, sit ups,
                squats, kumite etc. so you could end up doing 500 of these before you even started the lesson for the
                night. They were crazy days.</p>

            <p>After Sensei returned to Australia and settled, every second Sunday was compulsory training at Sensei's
                Dojo at Mary Smokes Creek in the Kilcoy ranges.</p>
            <p>Rob wrote about these Sunday sessions back in the 80's and has posted it in our articles section.</p>


            <hr/>
            <a href="#top" id="dave">Top</a>

            <h2>Sensei Dave Martin</h2>
            <p>Sensei Martin started Goju Ryu training in 1992 after he attended his first Karate class under Sensei Rob
                Williams, having previously studied different styles of martial arts training such as Zendo kai, Bo,
                Boxing classes from 1988. Dave enjoyed the strong training that Goju Ryu provided.</p>

            <p>Dave attended seminars and Gasshukus with teachers such as Graham Ravey, Glen Henry, Peter Oberaker, such
                as New Castle, Gold coast and the Sunshine coast. He won 4th place in the AMAC (Australian Martil Arts
                Championship) Kata competition.</p>

            <p>He is the only Dansha who has undertaken the 16 man-fight twice.</p>

            <p>Dave has inspired his children to train at his dojo twice a week and believes martial arts are good for
                them to build character. Sharing Karate with his children is one of the high lights of his Karate
                training.</p>

            <p>Since 2005 Dave has taught at the Caboolture Dojo under the watchful eyes of Sensei Rob Williams. Now he
                is the principal instructor at the Caboolture Dojo, Queensland in Australia.</p>

            <p>Since Sensei Ravey has returned to Australia in 2011 Sensei Martin has continued to train with him.</p>

        </Layout>);
}

export default Caboolture;
